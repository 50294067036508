<template>
    <v-card 
        outlined
        tile
        class="fill-height"
    >
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-select
                        v-model="form.chartType"
                        dense
                        hide-details
                        offset-y
                        outlined
                        label="チャートの種類を選ぶ"
                        :items="chartTypes"
                        item-value="value"
                        item-text="text"
                        @change="callDuct"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="pb-3">
                <v-col cols="12">
                    <v-select
                        v-model="form.dataType[0]"
                        :disabled="!(form.chartType !== null)"
                        dense
                        hide-details
                        outlined
                        label="棒グラフデータの種類を選ぶ"
                        :items="dataTypes"
                        item-value="value"
                        item-text="text"
                    ></v-select>
                </v-col>
            </v-row>

            <v-slide-y-transition group>
                <v-row v-if="form.chartType === 'time-series'" key="select-line-chart">
                    <v-col cols="12">
                        <v-select
                            v-model="form.dataType[1]"
                            dense
                            hide-details
                            outlined
                            label="折線グラフデータの種類を選ぶ (optional)"
                            :items="dataTypes"
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row v-if="form.chartType === 'time-series'" key="select-classif">
                    <v-col cols="12">
                        <v-select
                            v-model="form.classifType"
                            dense
                            hide-details
                            outlined
                            label="棒グラフデータの分類方法を選ぶ"
                            :items="classifTypes"
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row v-if="form.chartType === 'ind-prod'" key="autocomplete-product">
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.product.code"
                            dense
                            hide-details
                            outlined
                            :disabled="autocompleteStates.disabled"
                            :filter="filterProduct"
                            :label="autocompleteStates.label"
                            :items="productList"
                            item-value="product_code"
                        >
                            <template #selection="{ item, select, selected, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    small
                                    :input-value="selected"
                                    @click="select"
                                >{{ item.name + `(${item.product_code})`}}
                                </v-chip>
                            </template>
                            <template #item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name + ` (${item.product_code})`}}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-row v-if="form.chartType === 'ind-prod' && form.dataType[0] === 'shipments'" key="select-forecast">
                    <v-col cols="12" class="pb-0">
                        <v-combobox
                            v-model="form.forecastType"
                            deletable-chips
                            dense
                            hide-details
                            multiple
                            outlined
                            small-chips
                            label="比較する予測値を選ぶ"
                            :items="forecastTypes"
                            item-text="text"
                            item-value="value"
                        >
                            <template #selection="{ attrs, item, parent, selected }">
                                <v-chip
                                    v-if="item === Object(item)"
                                    v-bind="attrs"
                                    color="grey darken-1"
                                    :input-value="selected"
                                    label
                                    small
                                    dark
                                >          
                                    <span class="pr-2 chip-label">{{ item.text }}</span>
                                    <v-icon
                                        small
                                        @click="parent.selectItem(item)"
                                    >mdi-close
                                    </v-icon>
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>

                <v-row v-if="form.chartType === 'ranking'" key="checkbox-isDeviation">
                    <v-col class="py-0">
                        <v-checkbox
                            v-model="form.isDeviation"
                            dense
                            hide-details
                            label="差分を見る"
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-row key="radio-isRel">
                    <v-col cols="12" class="py-0">
                        <v-radio-group
                            class="pt-0" 
                            v-model="form.isRel"
                            hide-details
                        >
                            <v-radio
                                v-for="(choice, idx) in radioChoices"
                                :key="`radio_${idx}`"
                                :label="choice.text"
                                :value="choice.value"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>

                <v-row  v-if="form.chartType === 'ranking' && !form.isRel" key="chip-ranking-yw">
                    <v-col cols="12" class="pb-0">
                        <span>描画する週を選ぶ: </span>
                        <chip-menu
                            :list-items="years"
                            :chip-text="`${String(form.absTimeRange[1].year)}年`"
                            :chip-props="{
                                small: true,
                                outlined: true,
                                label: true,
                            }"
                            chip-class="mr-1" 
                            @click:list="form.absTimeRange[1].year=$event"
                        ></chip-menu>
                        <chip-menu
                            :list-items="weeks"
                            :chip-text="`第${String(form.absTimeRange[1].week)}週`"
                            :chip-props="{
                                small: true,
                                outlined: true,
                                label: true,
                            }"
                            @click:list="form.absTimeRange[1].week=$event"
                        ></chip-menu>
                    </v-col>
                </v-row>

                <yw-range-menu
                    v-if="![null, 'ranking'].includes(form.chartType) && !form.isRel"
                    key="ywrange-exceptranking-week-nonRel"
                    :acceptable-year="years"
                    :initial-yw-range="form.absTimeRange"
                    :chip-props="{
                        small: true,
                        outlined: true,
                        label: true,
                    }"
                    :row-class="{ 'mt-6': true }"
                    @click="getYwRange"
                ></yw-range-menu>
                <v-row 
                    v-if="![null, 'ranking'].includes(form.chartType) && form.isRel" 
                    key="chip-exceptranking-week-rel"
                >
                    <v-col cols="12">
                        <chip-menu
                            :list-items="weeks"
                            :chip-text="String(form.relTimeRange.range)"
                            :chip-props="{
                                small: true,
                                outlined: true,
                                label: true,
                            }"
                            chip-class="mr-1" 
                            @click:list="form.relTimeRange.range=$event"
                        ></chip-menu>
                        <span>週間前から現在まで</span>
                    </v-col>
                </v-row>
            </v-slide-y-transition>
        </v-card-text>
    </v-card>
</template>
<script>
import ChipMenu from './../../ui/ChipMenu'
import YwRangeMenu from './../../ui/YwRangeMenu.vue'
export default {
    model: {
        prop: 'form',
        event: 'change'
    },
    components: { 
        ChipMenu,
        YwRangeMenu
    },
    data: function() {
        return {
            chartTypes: [
                { text: '全商品対象の時系列推移', value: 'time-series' },
                { text: '特定週の商品順位', value: 'ranking' },
                { text: '特定商品の時系列推移', value: 'ind-prod' }
            ],
            dataTypes: [
                { text: '在庫', value: 'stocks' },
                { text: '出荷', value: 'shipments' },
                { text: '発注残', value: 'remains' },
                //{ text: '欠品率', value: 'stockout' },
                //{ text: '発注有無', value: 'is_ordered' },
                { text: '商品数', value: 'products' },
                { text: '顧客数', value: 'customers' }
            ],
            forecastTypes: [
                { text: '商品マスタに基づく予測値', value: 'master' },
                { text: '事後確率最大となる予測値', value: 'posterior' },
            ],
            weeks: [ ...Array(52) ].map((_, idx) => Number(idx) + 1),
            years: [2019, 2020, 2021, 2022],
            productList: [],
            autocompleteStates: {
                label: "Loading Product List...",
                disabled: true
            }
        }
    },
    props: {
        form: Object,
        duct: Object
    },
    computed: {
        immutableForm() {
            return JSON.parse(JSON.stringify(this.form))
        },
        productCode() {
            return this.form.product.code
        },
        radioChoices() {
            return this.form.chartType === 'ranking' ? [
                { text: '特定の週を表示', value: false },
                { text: '常に最新週を表示', value: true },
            ] : [
                { text: '特定の期間を表示', value: false },
                { text: '常に最新週からの期間を表示', value: true },
            ]
        },
        classifTypes() {
            let arr = [];
            if(['stocks', 'shipments', 'remains'].includes(this.form.dataType[0])) {
                arr = [
                    { text: '分類なし', value: 'no_classif' },
                    { text: 'ランク別', value: 'rank' },
                    { text: '安全率別', value: 'sr' },
                ];
            }else if(this.form.dataType[0] == 'products') {
                arr = [
                    { text: '分類なし', value: 'no_classif' },
                    { text: 'ランク別', value: 'rank' },
                    { text: '安全率別', value: 'sr' },
                    { text: 'メーカー別', value: 'supplier' },
                ];
            }else if(this.form.dataType[0] == 'customers') {
                arr = [ { text: '分類なし', value: 'no_classif' } ];
            }
            return arr
        },
    },
    watch: {
        immutableForm: {
            handler: async function(newObj, oldObj) {
                if(newObj.chartType !== oldObj.chartType && oldObj.chartType === 'time-series') {
                    this.form.dataType[1] = null;
                }
            },
            deep: true
        },
        productCode() {
            if(this.productList.length != 0) {
                const matchedProduct = this.productList.find(el => el.product_code === this.form.product.code);
                if(matchedProduct !== undefined) this.form.product.name = matchedProduct.name;
            }
        }
    },
    methods: {
        async callDuct() {
            if(this.form.chartType === 'ind-prod') {
                this.autocompleteStates = {
                    label: "Loading Product List...",
                    disabled: true
                };
                let ret = await this.duct.call(this.duct.EVENT.CHART_PRODUCTS_GET, { year: 2021, week: 30 });
                this.autocompleteStates = {
                    label: ret.content === 'no_data' ? "該当週に商品リストが登録されていません。" : "商品を選ぶ",
                    disabled: !ret.success
                };
                this.productList = ret.contents === 'no_data' ? [] : ret.content;
            }
        },
        onClickPreview() { 
            this.$emit('click:preview'); 
        },
        getYwRange(arr) {
            this.form.absTimeRange = arr;
        },
        filterProduct(item, queryText) {
            const prdName = item.name.toLowerCase();
            const prdCode = item.product_code.toLowerCase();
            const searchText = queryText.toLowerCase();
            return prdName.indexOf(searchText) > -1 || prdCode.indexOf(searchText) > -1
        }
    }
}
</script>
