<template>
    <v-card>
        <v-card-title class="grey lighten-3">チャートを追加する</v-card-title>
        <v-card-text class="pt-6 pb-0">
            <v-row>
                <v-col cols="7" class="pr-0">
                    <chart-preview
                        ref="preview"
                        :chart-component="chartComponent"
                        :chart-props="{
                            duct: duct,
                            ...chartProps
                        }" 
                    ></chart-preview>
                </v-col>
                <v-col cols="5" class="pl-0">
                    <chart-setting 
                        v-model="form"
                        :duct="duct"
                    ></chart-setting>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="error"
                @click="onClickCancel"
            >キャンセル
            </v-btn>
            <v-btn
                text
                color="primary"
                :disabled="confirmDisabled"
                @click="onClickConfirm"
            >確定
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import ChartPreview from './ChartPreview'
import ChartSetting from './ChartSetting'
export default {
    components: { 
        ChartPreview,
        ChartSetting
    },
    data: () => ({
        form: {
            chartType: null,
            dataType: [null, null],
            classifType: null,
            forecastType: [],
            product: {
                name: null,
                code: null
            },
            isRel: false,
            isDeviation: false,
            absTimeRange: [
                { year: null, week: null },
                { year: null, week: null },
            ],
            relTimeRange: {
                yw: {},
                range: null
            }
        },
    }),
    computed: {
        chartComponent() {
            return this.form.chartType !== null ? `${this.form.chartType}-chart` : ''
        },
        chartProps() {
            let _chartProps = { duct: this.duct, isDashboard: true };
            const currentYw = Object.assign({}, this.currentYw);
            switch(this.form.chartType) {
                case 'time-series':
                    _chartProps = {
                        ..._chartProps,
                        initialDataType: this.form.dataType,
                        initialRelTimeRange:  this.form.isRel ? this.form.relTimeRange : { yw: {}, range: 0 },
                        initialAbsTimeRange: !this.form.isRel ? this.form.absTimeRange : [],
                        initialClassif: this.form.classifType
                    };
                    break;
                case 'ranking':
                    _chartProps = {
                        ..._chartProps,
                        initialYw: this.form.isRel ? currentYw : this.form.absTimeRange[1],
                        initialDataType: this.form.dataType[0],
                        initialIsDeviation: this.form.isDeviation
                    };
                    break;
                case 'ind-prod':
                    _chartProps = {
                        ..._chartProps,
                        initialDataType: this.form.dataType[0],
                        initialRelTimeRange:  this.form.isRel ? this.form.relTimeRange : { yw: {}, range: 0 },
                        initialAbsTimeRange: !this.form.isRel ? this.form.absTimeRange : [],
                        initialForecastType: this.form.forecastType,
                        code: this.form.product.code,
                        name: this.form.product.name,
                    };
                    break;
                default:
                    break;
            }
            return _chartProps
        },
        confirmDisabled() {
            return this.chartComponent === '' || this.form.dataType.every(el => el === null) ? true : false
        }
    },
    props: ['duct', 'currentYw'],
    methods: {
        onClickCancel() {
            this.$emit('click:cancel');
            this.initializeForm();
            this.$refs.preview.initializePreview();
        },
        onClickConfirm() {
            this.$emit('click:confirm', { chartComponent: this.chartComponent, chartProps: this.chartProps });
            this.initializeForm();
            this.$refs.preview.initializePreview();
        },
        initializeForm() {
            this.form = {
                chartType: null,
                dataType: [null, null],
                classifType: 'no_classif',
                forecastType: [],
                product: {
                    name: null,
                    code: null
                },
                isRel: false,
                isDeviation: false,
                absTimeRange: [
                    { 
                        year: this.currentYw.week > 10 ? this.currentYw.year      : this.currentYw.year - 1, 
                        week: this.currentYw.week > 10 ? this.currentYw.week - 10 : 52 + (this.currentYw.week - 10)
                    },
                    Object.assign({}, this.currentYw)
                ],
                relTimeRange: {
                    yw: Object.assign({}, this.currentYw),
                    range: 10
                }
            };
        }
    },
    created() {
        this.initializeForm();
    },
}
</script>
