<template>
    <v-card
        class="fill-height d-flex align-center"
        outlined
        tile
        height="500"
    >
        <v-card-text>
            <component
                v-if="chartShown"
                :key="componentKey"
                :is="chartComponent"
                v-bind="chartProps"
            ></component>
        </v-card-text>
    </v-card>   
</template>
<script>
import TimeSeriesChart from './../../TimeSeriesChart'
import RankingChart from './../../RankingChart'
import IndProdChart from './../../IndProdChart'
export default {
    components: { 
        TimeSeriesChart,
        RankingChart,
        IndProdChart
    },
    data: function() {
        return {
            chartShown: false,
            componentKey: 0,
        }
    },
    props: {
        chartProps: {
            type: Object,
            default: () => ({})
        },
        chartComponent: {
            type: String,
            default: ''
        },
    },
    watch: {
        chartProps: {
            handler: function() { this.renderChart(); },
            deep: true
        }
    },
    methods: {
        renderChart() {
            const initialDataType = this.chartProps.initialDataType;
            const productCode = this.chartProps.code;
            const isDataTypeArray = Array.isArray(initialDataType);
            switch(this.chartComponent) {
                case 'time-series-chart':
                    this.chartShown = isDataTypeArray ? initialDataType.some(el => el !== null) : initialDataType !== null;
                    break;
                case 'ranking-chart':
                    this.chartShown = initialDataType !== null;
                    break;
                case 'ind-prod-chart':
                    this.chartShown = initialDataType !== null && productCode !== null;
                    break;
                default:
                    this.chartShown = false;
            }
            if(this.chartShown) this.componentKey++;
        },
        initializePreview() { this.chartShown = false; }
    },
}
</script>