<template>
    <v-dialog
        v-model="dialog"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-on="on"
                v-bind="attrs" 
                color="grey darken-1"
            ><v-icon>mdi-view-grid-plus</v-icon>チャートを追加する</v-btn>
        </template>
        <dialog-content
            :duct="duct"
            :current-yw="currentYw"
            @click:cancel="closeDialog"
            @click:confirm="onClickConfirm"
        ></dialog-content>
    </v-dialog>
</template>
<script>
import DialogContent from './DialogContent'
export default {
    components: { DialogContent },
    data: () => ({
        dialog: false
    }),
    props: ['duct', 'currentYw'],
    methods: {
        closeDialog() { this.dialog = false; },
        onClickConfirm(obj) {
            this.$emit('click:confirm', obj);
            this.closeDialog();
        }
    }
}
</script>
