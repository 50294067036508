<template>
    <v-card 
        tile 
        class="pa-3 ma-10"
        min-height="500"
        :loading="loading"
    >
        <v-card-title>
            ダッシュボード
            <v-slide-x-transition>
                <v-chip
                    v-if="editMode"
                    small 
                    color="yellow lighten-1"
                >編集モード
                </v-chip>
            </v-slide-x-transition>
            <v-spacer></v-spacer>
            <v-btn
                :color="editMode ? 'primary' : 'grey lighten-2'"
                @click="changeEditModeState"
            ><v-icon left>{{ editMode ? 'mdi-content-save-all' : 'mdi-wrench'}}</v-icon>{{ editMode ? '保存する' : '編集する'}}
            </v-btn>
        </v-card-title>
        <v-slide-y-transition>
            <v-card-text v-if="editMode">
                <v-row>
                    <v-col cols="12" class="pb-0">
                        チャートをドラッグして配置を変更できます。
                    </v-col>
                </v-row>
            </v-card-text>
        </v-slide-y-transition>
        <draggable 
            v-model="registeredCharts" 
            tag="v-row"
            :disabled="!editMode"
            animation="400"
        >
            <v-col 
                v-for="(chart, idx) in registeredCharts"
                :key="chart.id"
                cols="12"
                md="6"
                lg="6"
                xl="4"
            >
                <component
                    :is="chart.chartComponent"
                    v-bind="{
                        ...chart.props,
                        ...{ duct: duct }
                    }"
                    ref="chart"
                    @click:close="deleteChart(idx)"
                ></component>
            </v-col>
            <v-col 
                v-if="editMode" 
                cols="12"
                md="6"
                lg="6"
                xl="4"
            >
                <v-hover #default="{ hover }">
                    <v-card
                        class="fill-height d-flex align-center"
                        min-height="450"
                        outlined
                        :elevation="!editMode ? 2 : (hover ? 20 : 5)"
                    >
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center">
                                <v-icon x-large>mdi-view-grid-plus</v-icon>
                            </v-col>
                        </v-row>
                        <v-fade-transition>
                            <v-overlay
                                v-show="hover"
                                absolute
                            >
                                <edit-dialog
                                    :duct="duct"
                                    :current-yw="currentYw"
                                    @click:confirm="addNewChart"
                                ></edit-dialog>
                            </v-overlay>
                        </v-fade-transition>
                    </v-card>
                </v-hover>
            </v-col>
        </draggable>
        <v-snackbar
            v-model="snackbar.isShown"
            :color="snackbar.color"
            timeout="3000"
        >
            {{ snackbar.text }}
            <template #action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar.isShown = false"
                >Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>
<script>
import TimeSeriesChart from './../TimeSeriesChart'
import RankingChart from './../RankingChart'
import IndProdChart from './../IndProdChart'
import EditDialog from './EditDialog/index'
import ChipMenu from './../ui/ChipMenu'
import draggable from 'vuedraggable'
export default {
    components: { 
        TimeSeriesChart,
        RankingChart,
        IndProdChart,
        EditDialog,
        ChipMenu,
        draggable
    },
    data() {
        return {
            dialog: false,
            editMode: false,
            loading: false,
            username: '',
            registeredCharts: [],
            currentYw: {},
            snackbar: {
                isShown : false,
                text: '',
                color: ''
            },
            defaultCharts: [
                { 
                    chartComponent: 'time-series-chart', 
                    props: {
                        initialDataType: 'stocks',
                        initialAbsTimeRange: [
                            { year: 2021, week: 25 },
                            { year: 2021, week: 35 },
                        ],
                        isDashboard: true,
                        currentYw: this.currentYw
                    },
                    isRel: false,
                    id: this.createRandStr()
                },
                { 
                    chartComponent: 'time-series-chart', 
                    props: {
                        initialDataType: 'remains',
                        initialAbsTimeRange: [
                            { year: 2021, week: 25 },
                            { year: 2021, week: 35 },
                        ],
                        isDashboard: true,
                        currentYw: this.currentYw
                    },
                    isRel: false,
                    id: this.createRandStr()
                },
                { 
                    chartComponent: 'time-series-chart', 
                    props: {
                        initialDataType: 'shipments',
                        initialAbsTimeRange: [
                            { year: 2021, week: 25 },
                            { year: 2021, week: 35 },
                        ],
                        isDashboard: true,
                        currentYw: this.currentYw
                    },
                    isRel: false,
                    id: this.createRandStr()
                },
                { 
                    chartComponent: 'time-series-chart', 
                    props: {
                        initialDataType: 'products',
                        initialAbsTimeRange: [
                            { year: 2021, week: 25 },
                            { year: 2021, week: 35 },
                        ],
                        isDashboard: true
                    },
                    isRel: false,
                    id: this.createRandStr()
                },
                { 
                    chartComponent: 'time-series-chart', 
                    props: {
                        initialDataType: 'customers',
                        initialAbsTimeRange: [
                            { year: 2021, week: 25 },
                            { year: 2021, week: 35 },
                        ],
                        isDashboard: true,
                        currentYw: this.currentYw
                    },
                    isRel: false,
                    id: this.createRandStr()
                },
            ],
        }
    },
    props: ['duct'],
    watch: {
        currentYw() {
            this.registeredCharts.forEach((el, idx) => {
                if(el.isRel) this.registeredCharts[idx].props.currentYw = this.currentYw;
            })
            this.$forceUpdate();
        },
    },
    methods: {
        changeEditModeState(isSwitch=true, bool) {
            this.editMode = isSwitch ? !this.editMode : bool;
            if(this.$refs.chart) this.$refs.chart.forEach(component => { component.changeEditModeState(this.editMode); });
            if(!this.editMode && isSwitch) this.saveDashboardCondition();
        },
        addNewChart(obj) {
            this.registeredCharts.push({
                chartComponent: obj.chartComponent,
                props: obj.chartProps,
                id: this.createRandStr()
            });
        },
        deleteChart(idx) { this.registeredCharts.splice(idx, 1); },
        async saveDashboardCondition() {
            const ret = await this.duct.call(this.duct.EVENT.CHART_DASHBOARD_SET, {
                username: this.username, 
                content: this.stringifyRegisteredCharts()
            });
            this.snackbar = {
                isShown: true,
                text: ret.success ? 'カスタムダッシュボードを保存しました。' : 'ダッシュボードの保存に失敗しました。',
                color: ret.success ? 'grey darken-2' : 'error'
            };
        },
        createRandStr() { return btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(16)))).substring(0,16) },
        stringifyRegisteredCharts() { return JSON.stringify(this.registeredCharts); },
    },
    created() {
        this.loading = true;
        this.username = window.localStorage.getItem('YhtUsername');
        this.duct.invokeOnOpen(async () => {
            this.duct.addEventHandler(
                this.duct.EVENT.WEEK_GET_INFO, { 
                    success: (rid, eid, data) => { 
                        this.currentYw = { year: data.now.year, week: data.week };
                        this.defaultCharts.forEach(el => el.props.currentYw = this.currentYw);
                    }
                }
            );
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.WEEK_GET_INFO, { time: new Date().getTime()/1000 });

            this.duct.addEventHandler(
                this.duct.EVENT.CHART_DASHBOARD_GET,
                {
                    success: (rid, eid, data) => { 
                        this.registeredCharts = JSON.parse(data); 
                    },
                    error: async (rid, eid, data) => {
                        switch(data.error_code) {
                            case this.duct.ERROR.CHART_DASHBOARD_GET.NO_DATA:
                                this.snackbar = {
                                    isShown: true,
                                    text: 'デフォルト設定を読み込みました。',
                                    color: 'grey darken-2'
                                };
                                this.registeredCharts = this.defaultCharts;
                                await this.duct.call(this.duct.EVENT.CHART_DASHBOARD_SET, {
                                    username: this.username, 
                                    content: this.stringifyRegisteredCharts()
                                });
                                break;
                        }
                    },
                    complete: () => { 
                        this.loading = false; 
                        setTimeout(() => { this.changeEditModeState(false, false); }, 500);
                    }
                }
            );
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.CHART_DASHBOARD_GET, { username: this.username });
        });
    },
}
</script>
